// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-tsx": () => import("./../../../src/pages/acerca.tsx" /* webpackChunkName: "component---src-pages-acerca-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-datos-tsx": () => import("./../../../src/pages/datos.tsx" /* webpackChunkName: "component---src-pages-datos-tsx" */),
  "component---src-pages-documentos-tsx": () => import("./../../../src/pages/documentos.tsx" /* webpackChunkName: "component---src-pages-documentos-tsx" */),
  "component---src-pages-glosario-tsx": () => import("./../../../src/pages/glosario.tsx" /* webpackChunkName: "component---src-pages-glosario-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-ligas-de-interes-tsx": () => import("./../../../src/pages/ligas-de-interes.tsx" /* webpackChunkName: "component---src-pages-ligas-de-interes-tsx" */),
  "component---src-pages-mapas-tsx": () => import("./../../../src/pages/mapas.tsx" /* webpackChunkName: "component---src-pages-mapas-tsx" */),
  "component---src-pages-pnif-tsx": () => import("./../../../src/pages/pnif.tsx" /* webpackChunkName: "component---src-pages-pnif-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-seguimiento-de-indicadores-tsx": () => import("./../../../src/pages/seguimiento-de-indicadores.tsx" /* webpackChunkName: "component---src-pages-seguimiento-de-indicadores-tsx" */)
}

