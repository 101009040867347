// noinspection JSValidateTypes

import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports"
import "@aws-amplify/ui/dist/style.css"

import config from "react-reveal/globals"

import "./src/global.css"
import "./src/components/_common/Button/buttonLoader.css"

import moment from "moment"
import "moment/locale/es"


config( { ssrFadeout: true } )

moment.locale( "es" )

Amplify.configure( awsConfig )
